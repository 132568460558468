import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import Parser from "../components/parser";

const PostArchive = (props) => {
  const postsPage = props.data.wpPage;
  let infos = {};
  if (props.pageContext.category) {
    infos = {
      postType: "category",
      title: props.data.wpCategory.name,
      path: props.pageContext
    };
  } else {
    infos = {
      title: postsPage.title,
      postType: "archive"
    };
  }
  const seo = props.pageContext.category ? props.data.wpCategory.seo : postsPage.seo;
  seo.nextPagePath = props.pageContext.nextPagePath;
  seo.previousPagePath = props.pageContext.previousPagePath;
  return (
    <Layout infos={infos}  seo={ seo }>
      <Parser
        content={ postsPage.content }
        terms={{
          nodes: props.data.allWpCategory.nodes,
          asList: true,
          asLink: true,
          single: (props.pageContext.category ? props.data.wpCategory : undefined)
        }}
        pagination={{
          currentPage: props.pageContext.currentPage,
          totalPages: props.pageContext.totalPages,
          canonicalPath: props.pageContext.canonicalPath,
        }}
        latestPost={ props.data.wpPost }
        allPosts={ [props.data.wpPost, ...props.data.allWpPost.nodes] }
        title={ props.pageContext.category ? props.data.wpCategory.name : postsPage.title }
      />
    </Layout>
  );
};

export const query = graphql`
  query ($lastArticle: String = "", $category: String = "//", $offset: Int!, $postsPerPage: Int!) {
    allWpCategory {
      nodes {
        uri
        name
        id
      }
    }
    wpPost(id: { eq: $lastArticle }) {
      id
      excerpt
      uri
      title
      slug
      date
      categories {
        nodes {
          slug
          uri
          name
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              thumb: gatsbyImageData(width: 200)
              small: gatsbyImageData(width: 400)
              medium: gatsbyImageData(width: 800)
              large: gatsbyImageData(width: 1000)
              xl: gatsbyImageData(width: 1200)
              full: gatsbyImageData
            }
          }
        }
      }
    }
    allWpPost(
      filter: {
        categories: {
          nodes: {
            elemMatch: {
              slug: {
                regex: $category
              }
            }
          }
        }
        seo: {
          redirectUrl: {
            eq: ""
          }
        }
      }
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        id
        excerpt
        uri
        title
        slug
        date
        categories {
          nodes {
            slug
            uri
            id
            name
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                thumb: gatsbyImageData(width: 200)
                small: gatsbyImageData(width: 400)
                medium: gatsbyImageData(width: 800)
                large: gatsbyImageData(width: 1000)
                xl: gatsbyImageData(width: 1200)
                full: gatsbyImageData
              }
            }
          }
        }
      }
    }
    wpPage(isPostsPage: {eq: true}) {
      content
      title
      seo {
        canonicalUrl
        description
        openGraphDescription
        openGraphTitle
        openGraphType
        title
        twitterDescription
        twitterTitle
        noIndex
        noArchive
        noFollow
        socialImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    wpCategory(slug: {regex: $category}) {
      name
      id
      description
      seo {
        canonicalUrl
        description
        openGraphDescription
        openGraphTitle
        openGraphType
        title
        twitterDescription
        twitterTitle
        noIndex
        noArchive
        noFollow
        socialImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
export default PostArchive;
